<template>
  <div style="padding: 20px;">
    <v-layout wrap>
      <v-flex xs6>
        <p
          v-for="(item) of notFound"
          :key="item.ID"
        >
          <a @click="handleEdit(item.ID)">{{item.ID}}</a>
        </p>

        <p
          v-for="(item) of found.filter(x => `${x.id}${x.tag}`.indexOf('opia') < 0 )"
          :key="`${item.id}${item.tag}`"
        >
        <a @click="handleExistEdit(item)" style="color: red;">{{item.id}} [{{item.tag}}]</a>
        </p>
      </v-flex>
      <v-flex xs6>
        <acttiv-image-form 
          v-if="editedID" 
          :editedID="editedID" 
          :onClose="handleClose"
          :actualURL="actualURL"
          :actualTag="actualTag"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from 'axios'
import ActtivImageForm from './ActtivImageForm'
export default {
  components: {
    ActtivImageForm,
  },
  data: () => ({
    notFound: [],
    found: [],
    editedID: null,
    actualURL: null,
    actualTag: 'Wefamily',
  }),
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      axios.get('https://www.dviewcontent.com/api/public/barcelo/')
        .then(response => {
          this.notFound = response.data.notFound
          this.found = response.data.found
        })
    },
    handleExistEdit (v) {
      this.actualURL = v.url
      this.actualTag = v.tag
      this.editedID = v.id
    },
    handleEdit (v) {
      this.actualURL = null
      this.actualTag = 'Wefamily'
      this.editedID = v
    },
    handleClose (refresh) {
      this.actualURL = null
      this.actualTag = 'Wefamily'
      this.editedID = null
      if(refresh) this.handleGetData()
    },
  },
}
</script>
